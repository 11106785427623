import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {
    Button,
    Col, FormFeedback,
    FormGroup,
    FormText, Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import FormTextField from "../../../common/formComponents/FormTextField";
import FormDropdown from "../../../common/formComponents/FormDropdown";
import * as yup from "yup";
import {useSelector} from "react-redux";
import {getInquiryByCoUserId, InquiryRegister} from "../../../services/InquiryService";
import {toast} from "react-toastify";

const InquiryModel = (props) =>{
    const { isOpen, toggleModal, rowData, isNew,fetchPayeeRegisterData } = props;

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const user = useSelector((state) => state.auth.data.userDTO);
    const isAccountActive = user?.isActive;

    const validationSchema = yup.object().shape({
        name: yup.string().required(t("NAME_IS_REQUIRED")),
        email: yup.string().email(t("EMAIL_MUST_BE_VALID")).required(t("EMAIL_IS_REQUIRED")),
        messageTitle: yup.string().required(t("MESSAGE_TITLE_IS_REQUIRED")).max(150,t("MESSAGE_TITLE_MUST_BE_AT_MOST_150_CHARACTERS"))
            .min(5,t("MESSAGE_TITLE_MUST_BE_AT_LEAST_5_CHARACTERS")),
        message: yup.string().required(t("MESSAGE_IS_REQUIRED")),
    })


    const onSubmit = async (values) =>{

        setLoading(true)

        let coUserId = user ? user.coUserId : ''

        let data = {
            backofficeUserId: null,
            coUserId: coUserId,
            description: values.message,
            inquiryId: null,
            response: null,
            status: "PENDING",
            title: values.messageTitle
        }

        try {
            const response = await InquiryRegister(data)
            toast.success(t("REGISTER_SUBMITTED_SUCCESS"))
            fetchPayeeRegisterData()
            setLoading(false)
            toggleModal()
        }catch (error){
            setLoading(false)
            toast.error(t("ERROR_INQUIRY_SUBMITTED"))
        }

    }

    return (
        <div>
            <Modal isOpen={isOpen} centered={true} size="lg">
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: user.firstName ? user.firstName : '',
                        email: user.businessEmail ? user.businessEmail : '',
                        messageTitle: "",
                        message: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting, submitForm }) => (
                        <Form>
                            <ModalHeader style={{backgroundColor: 'rgb(3, 35, 112)', color: 'white' }}>
                                {t("ADD_INQUIRY")}
                            </ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="name">{t("NAME")}
                                                <sup className="text-danger"> *</sup>
                                            </Label>
                                            <FormTextField
                                                name="name"
                                                id="name"
                                                placeholder={t("NAME")}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="email">{t("EMAIL")}
                                                <sup className="text-danger"> *</sup>
                                            </Label>
                                            <FormTextField
                                                name="email"
                                                type="email"
                                                id="email"
                                                placeholder={t("EMAIL")}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={12} xl={12}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="messageTitle">{t("MESSAGE_TITLE")}
                                                <sup className="text-danger"> *</sup>
                                            </Label>
                                            <FormTextField
                                                name="messageTitle"
                                                type="messageTitle"
                                                id="messageTitle"
                                                placeholder={t("MESSAGE_TITLE")}
                                               // disabled={isAccountActive ? false : true}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={12} xl={12}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="message">{t("MESSAGE")}
                                                <sup className="text-danger"> *</sup>
                                            </Label>
                                            <FormTextField
                                                style={{ minHeight: '100px' }}
                                                name="message"
                                                type="textarea"
                                                id="message"
                                                placeholder={t("MESSAGE")}
                                               // disabled={isAccountActive ? false : true}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="secondary w-md" onClick={toggleModal}>
                                    {t("CLOSE")}
                                </Button>
                                <Button className="btn-primary w-md" type="submit"
                                        //disabled={isAccountActive ? false : true}

                                >
                                    {t("SUBMIT")}{' '}{' '}
                                    { loading ? (
                                        <Spinner size="sm">
                                            Loading...
                                        </Spinner>
                                    ) : "" }
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );

}

export default InquiryModel;