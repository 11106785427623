import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { Form, Formik } from "formik";
import FormTextField from "../../../common/formComponents/FormTextField";
import FormDropdown from "../../../common/formComponents/FormDropdown";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { toast } from "react-toastify";
import {
  DeletePayeeRegister, GetConfirmationLetter,
  getPayeePurpose, getPayeeRegisterData,
  getPayeeStatus,
  getPayeeTransactionHistory,
  getPayeeTransactionHistoryByPayeeId,
  PayeeTransactionHistorySearch,
} from "../../../services/PayeeService";
import PayeeTransactionEditModal from "./payeeTransactionEditModal";
import MaterialTableCustom from "../../../common/tables/MaterialTableCustom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const PayeeTransactionHistory = (props) => {
  const { t } = useTranslation();

  const [editTooltip, setEditTooltip] = useState(false);
  const [
    isOpenPayeeTransactionHistoryModal,
    setIsOpenPayeeTransactionHistoryModal,
  ] = useState(false);
  const [clickedRowData, setClickedRowData] = useState(null);
  const location = useLocation();

  const user = useSelector((state) => state.auth.data.userDTO);
  const isAccountActive = user?.isActive;

  // Parse the query parameters from the location object
  const queryParams = new URLSearchParams(location.search);

  // Get the value of the 'payee_id' parameter
  const payee_id = queryParams.get("payee_id");

  const [loading, setLoading] = useState(false);

  const toggleTransactionHistoryModal = () =>
    setIsOpenPayeeTransactionHistoryModal(!isOpenPayeeTransactionHistoryModal);
  // Payee Transaction History Table

  const [columns, setColumns] = useState([
    { title: t("DATE_&_TIME"), field: "date_and_time", width: 200 },
    { title: t("PAYEE_NAME"), field: "payee_name", width: 200 },
    { title: t("PAID_CURRENCY"), field: "paid_currency", width: 200 },
    { title: t("PAID_AMOUNT"), field: "paid_amount", width: 200 },
    { title: t("TRANSFER_FEE"), field: "transfer_fee_amount", width: 200 },
    { title: t("STATUS"), field: "payee_transaction_status", width: 50 },
   // { title: t("PURPOSE"), field: "purpose", width: 200 },
   //  {
   //    title: t("CONVERSION_CURRENCY"),
   //    field: "conversion_Currency",
   //    width: 290,
   //  },
   // { title: t("ERROR_MESSAGE"), field: "errorMessage", width: 290 },
  ]);

  const [isTableData, setTableData] = useState([]);

  // Payee Transaction History

  const [isPurpose, setPurpose] = useState([]);
  const [isPayeeName, setPayeeName] = useState([]);

  const [isStatus, setStatus] = useState([]);

  useEffect(() => {
    if (isAccountActive) {
      fetchPayeeStatus();
      fetchPayees();
      // fetchPayeePurpose();

      if (payee_id === null) {
        fetchPayeeTransactionHistory();
      } else {
        getPayeeTransactionHistoryByPayee(payee_id);
      }
    }
  }, [payee_id, isAccountActive]);

  const fetchPayees = async () => {
    try {
      const data = {
        co_user_id: user.coUserId,
      };
      const response = await getPayeeRegisterData(data);
      const payees = response.result.map((payee) => {
        if (payee.payee_type === "PERSONAL") {
          return {
            value: payee.payee_id,
            label: payee.first_name && payee.last_name
                ? `${payee.account_name} - ${payee.first_name} ${payee.last_name}`
                : payee.account_name, // Show only account_name if first_name or last_name is null
          };
        } else {
          return {
            value: payee.payee_id,
            label: payee.company_name
                ? `${payee.account_name} - ${payee.company_name}`
                : payee.account_name, // Show only account_name if company_name is null
          };
        }
      });
      setPayeeName(payees);
    } catch (error) {
      toast.error(t("ERROR_FETCH_PAYEE"));
    }
  };

  // table edit button

  const fetchPayeeStatus = async () => {
    try {
      const response = await getPayeeStatus();

      let payeeStatus = response.result.map((payeeStatus) => ({
        label: payeeStatus.status.replace(/_/g, ' '),
        value: payeeStatus.status,
      }));

      setStatus(payeeStatus);
    } catch (error) {
      toast.error(t("ERROR_FETCH_PAYEE_STATUS"));
    }
  };

  const fetchPayeePurpose = async () => {
    try {
      const response = await getPayeePurpose();
      let payeePurpose = response.result.map((payeePurpose) => ({
        label: payeePurpose.purpose,
        value: payeePurpose.purpose,
      }));

      setPurpose(payeePurpose);
    } catch (error) {
      //toast.error(t("ERROR_FETCH_PAYEE_PURPOSE"));
    }
  };

  const validationSchema = yup.object().shape({
    periodFrom: yup.string().required(t("PERIOD_FROM_IS_REQUIRED")),
    periodTo: yup.string().required(t("PERIOD_TO_IS_REQUIRED")),
  });

  const fetchPayeeTransactionHistory = async () => {

    setLoading(true)

    let data = {
      co_user_id: user ? user.coUserId : "",
    };

    try {
      const response = await getPayeeTransactionHistory(data);

      const sortedData = response.result.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA;
      });


      // let payeeName = sortedData.map((payeeName) => ({
      //   label: payeeName.payee_nick_name,
      //   value: payeeName.payee_id,
      // }));
      //
      // setPayeeName(payeeName)

      let registerData = sortedData.map((registerData) => {
        let formattedDate = "";

        if (registerData.completed_date) {
          // Parse the date string and format it
          const parsedDate = new Date(registerData.completed_date);

          // Format the hours and minutes with leading zeros
          const formattedHours = ("0" + parsedDate.getHours()).slice(-2);
          const formattedMinutes = ("0" + parsedDate.getMinutes()).slice(-2);

          formattedDate = `${parsedDate.getFullYear()}-${
            parsedDate.getMonth() + 1
          }-${parsedDate.getDate()}, ${formattedHours}:${formattedMinutes}`;
        } else {
          formattedDate = "";
        }

        let errorMessage = registerData.error_message;

        if (errorMessage && errorMessage.includes("beneficiary.address.")) {
          // Extract street_address from the error message
          errorMessage = errorMessage.replace("beneficiary.address.", "");
        }

        // if (errorMessage){
        //     errorMessage = `ERROR : ${errorMessage}`
        // }

        return {
          ...registerData,
          date_and_time: formattedDate,
          payee_name: registerData.payee_nick_name || registerData.company_name || registerData.first_name || '-',
          paid_currency: registerData.fee_currency,
          paid_amount: registerData.payment_amount,
          payee_transaction_status: registerData.status,
          purpose: registerData.reason,
          paid_by: registerData.fee_paid_by,
          conversion_Currency: registerData.conversion_currency,
          errorMessage: errorMessage,
        };
      });

      const modifiedData = registerData.map((item) => {
        // Check payee_transaction_status and add the appropriate Badge
        const badgeColor =
          item.payee_transaction_status.toLowerCase() === "pending"
            ? "bg-warning"
            : item.payee_transaction_status.toLowerCase() === "approved"
            ? "bg-custom-success"
            : item.payee_transaction_status.toLowerCase() === "failed"
            ? "bg-danger"
                      : item.payee_transaction_status.toLowerCase() === "ready_for_dispatch" ? "bg-ready-for-dispatch"
                      : item.payee_transaction_status.toLowerCase() === "in_review" ? "bg-in-review"
                      : item.payee_transaction_status.toLowerCase() === "dispatched" ? "bg-dispatched"
            : "bg-success";
        const badgeText =
          item.payee_transaction_status.toLowerCase() === "pending"
            ? "PENDING"
            : item.payee_transaction_status.toLowerCase() === "approved"
            ? "APPROVED"
            : item.payee_transaction_status.toLowerCase() === "failed"
            ? "FAILED"
            : `${item.payee_transaction_status.toUpperCase()}`;

        return {
          ...item,
          payee_transaction_status: (
            <Badge className={`py-1 ${badgeColor}`}>{badgeText.replace(/_/g, ' ')}</Badge>
          ),
        };
      });

      setTableData(modifiedData);
      setLoading(false)
    } catch (error) {
      if(error.response.data.errorType === ""){
      //  toast.error(`Error: ${error.response.data.result.error}`)
      }else{
       // toast.error(t("ERROR_FETCH_TRANSACTION_HISTORY"));
      }
      setLoading(false)
    }
  };

  const onSearchTransactionHistory = async (values, { resetForm }) => {

    setLoading(true)

    if (values.status === "Select an option") {
      values.status = "";
    }

    if (values.purpose === "Select an option") {
      values.purpose = "";
    }

    let data = {
      startDate: values.periodFrom,
      endDate: values.periodTo,
      status: values.status.toUpperCase(),
      purpose: values.purpose,
      co_user_id: user.coUserId,
      payeeId:values.payee
    };

    try {
      const response = await PayeeTransactionHistorySearch(data);

      const sortedData = response.result.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA;
      });

      let registerData = sortedData.map((registerData) => {
        let formattedDate = "";

        if (registerData.completed_date) {
          // Parse the date string and format it
          const parsedDate = new Date(registerData.completed_date);

          // Format the hours and minutes with leading zeros
          const formattedHours = ("0" + parsedDate.getHours()).slice(-2);
          const formattedMinutes = ("0" + parsedDate.getMinutes()).slice(-2);

          formattedDate = `${parsedDate.getFullYear()}-${
            parsedDate.getMonth() + 1
          }-${parsedDate.getDate()}, ${formattedHours}:${formattedMinutes}`;
        } else {
          formattedDate = "";
        }

        let errorMessage = registerData.error_message;

        if (errorMessage && errorMessage.includes("beneficiary.address.")) {
          // Extract street_address from the error message
          errorMessage = errorMessage.replace("beneficiary.address.", "");
        }

        return {
          ...registerData,
          date_and_time: formattedDate,
          payee_name: registerData.payee_nick_name || registerData.company_name || registerData.first_name || '-',
          paid_currency: registerData.fee_currency,
          paid_amount: registerData.payment_amount,
          payee_transaction_status: registerData.status,
          purpose: registerData.reason,
          paid_by: registerData.fee_paid_by,
          conversion_Currency: registerData.conversion_currency,
          errorMessage: errorMessage,
        };
      });

      const modifiedData = registerData.map((item) => {
        // Check payee_transaction_status and add the appropriate Badge
        const badgeColor =
            item.payee_transaction_status.toLowerCase() === "pending"
                ? "bg-warning"
                : item.payee_transaction_status.toLowerCase() === "approved"
                    ? "bg-custom-success"
                    : item.payee_transaction_status.toLowerCase() === "failed"
                        ? "bg-danger"
                        : item.payee_transaction_status.toLowerCase() === "ready_for_dispatch" ? "bg-ready-for-dispatch"
                            : item.payee_transaction_status.toLowerCase() === "in_review" ? "bg-in-review"
                                : item.payee_transaction_status.toLowerCase() === "dispatched" ? "bg-dispatched"
                                    : "bg-success";
        const badgeText =
          item.payee_transaction_status.toLowerCase() === "pending"
            ? "PENDING"
            : item.payee_transaction_status.toLowerCase() === "approved"
            ? "APPROVED"
            : item.payee_transaction_status.toLowerCase() === "failed"
            ? "FAILED"
            : `${item.payee_transaction_status.toUpperCase()}`;

        return {
          ...item,
          payee_transaction_status: (
            <Badge className={`py-1 ${badgeColor}`}>{badgeText.replace(/_/g, ' ')}</Badge>
          ),
        };
      });

      setTableData(modifiedData);
      setLoading(false)
     // resetForm()
    } catch (error) {
      if(error.response.data.errorType === ""){
        toast.error(`Error: ${error.response.data.result.error}`)
      }else{
        toast.error(t("ERROR_SEARCH_TRANSACTION_HISTORY"));
      }
      setLoading(false)

    }
  };

  const getPayeeTransactionHistoryByPayee = async (payee_id) => {

    setLoading(true)

    try {
      const response = await getPayeeTransactionHistoryByPayeeId(payee_id);


      const sortedData = response.result.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA;
      });

      //
      // let payeeName = sortedData.map((payeeName) => ({
      //   label: payeeName.payee_nick_name,
      //   value: payeeName.payee_id,
      // }));
      //
      // setPayeeName(payeeName)

      let registerData = sortedData.map((registerData) => {
        let formattedDate = "";

        if (registerData.completed_date) {
          // Parse the date string and format it
          const parsedDate = new Date(registerData.completed_date);

          // Format the hours and minutes with leading zeros
          const formattedHours = ("0" + parsedDate.getHours()).slice(-2);
          const formattedMinutes = ("0" + parsedDate.getMinutes()).slice(-2);

          formattedDate = `${parsedDate.getFullYear()}-${
            parsedDate.getMonth() + 1
          }-${parsedDate.getDate()}, ${formattedHours}:${formattedMinutes}`;
        } else {
          formattedDate = "";
        }

        let errorMessage = registerData.error_message;

        if (errorMessage && errorMessage.includes("beneficiary.address.")) {
          // Extract street_address from the error message
          errorMessage = errorMessage.replace("beneficiary.address.", "");
        }

        return {
          ...registerData,
          date_and_time: formattedDate,
          payee_name: registerData.payee_nick_name || "-",
          paid_currency: registerData.fee_currency,
          paid_amount: registerData.payment_amount,
          payee_transaction_status: registerData.status,
          purpose: registerData.reason,
          paid_by: registerData.fee_paid_by,
          conversion_Currency: registerData.conversion_currency,
          errorMessage: errorMessage,
        };
      });

      const modifiedData = registerData.map((item) => {
        // Check payee_transaction_status and add the appropriate Badge
        const badgeColor =
            item.payee_transaction_status.toLowerCase() === "pending"
                ? "bg-warning"
                : item.payee_transaction_status.toLowerCase() === "approved"
                    ? "bg-custom-success"
                    : item.payee_transaction_status.toLowerCase() === "failed"
                        ? "bg-danger"
                        : item.payee_transaction_status.toLowerCase() === "ready_for_dispatch" ? "bg-ready-for-dispatch"
                            : item.payee_transaction_status.toLowerCase() === "in_review" ? "bg-in-review"
                                : item.payee_transaction_status.toLowerCase() === "dispatched" ? "bg-dispatched"
                                    : "bg-success";
        const badgeText =
          item.payee_transaction_status.toLowerCase() === "pending"
            ? "PENDING"
            : item.payee_transaction_status.toLowerCase() === "approved"
            ? "APPROVED"
            : item.payee_transaction_status.toLowerCase() === "failed"
            ? "FAILED"
            : `${item.payee_transaction_status.toUpperCase()}`;

        return {
          ...item,
          payee_transaction_status: (
            <Badge className={`py-1 ${badgeColor}`}>{badgeText.replace(/_/g, ' ')}</Badge>
          ),
        };
      });

      setTableData(modifiedData);
      setLoading(false)
    } catch (error) {
      if(error.response?.data?.errorType === ""){
       // toast.error(`Error: ${error.response.data.result.error}`)
      }else {
       //toast.error(t("ERROR_FETCH_TRANSACTION_HISTORY"));
      }
      setLoading(false)

    }
  };

  // table view button action event
  const tableRowViewHandler = (rowData) => {
    // Save the clicked row data
    setClickedRowData(rowData);
    toggleTransactionHistoryModal();
  };

  const clearForm = (resetForm) => {
    resetForm()
    fetchPayeeTransactionHistory()
  };

  const downloadConfirmationLetter = async (data) => {
    // console.log("Download Confirmation Letter", data)
    setLoading(true)
    try {
      let request = {
        coUserId: data.co_user_id,
        paymentId: data.payment_id
      }
      const response = await GetConfirmationLetter(request)
      const base64Data = response.result;
      console.log("base64Data", base64Data)

      //Convert Base64 string to Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Create a URL for the Blob and open it in a new tab
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    } catch (error) {
      toast.warn("Warning: Confirmation Letter Not Available")
    } finally {
      setLoading(false)
    }
  }

  document.title = t("PAYEE_TRANSACTION_HISTORY_TPS_SOLUTIONS");
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <br />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    {t("PAYEE_TRANSACTION_HISTORY")}
                  </CardTitle>

                  <Formik
                    enableReinitialize
                    initialValues={{
                      periodFrom: "",
                      periodTo: "",
                      status: "",
                      purpose: "",
                      payee:"",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSearchTransactionHistory}
                  >
                    {({ isSubmitting ,resetForm}) => (
                      <Form>
                        <Row className="mt-4">
                          <Col md={3} xl={4}>
                            <FormGroup className="mb-3">
                              <Label htmlFor="periodFrom">
                                {t("PERIOD_FROM")}
                                <sup className="text-danger"> *</sup>
                              </Label>
                              <FormTextField
                                name="periodFrom"
                                id="periodFrom"
                                type="date"
                                placeholder={t("PERIOD_FROM")}
                                disabled={isAccountActive ? false : true}
                              />
                            </FormGroup>
                          </Col>

                          <Col md={3} xl={4}>
                            <FormGroup className="mb-3">
                              <Label htmlFor="periodTo">
                                {t("PERIOD_TO")}
                                <sup className="text-danger"> *</sup>
                              </Label>
                              <FormTextField
                                name="periodTo"
                                id="periodTo"
                                type="date"
                                placeholder={t("PERIOD_TO")}
                                disabled={isAccountActive ? false : true}
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6} xl={4}>
                            <FormGroup className="mb-3">
                              <Label htmlFor="status">{t("STATUS")}</Label>
                              <FormDropdown
                                name="status"
                                id="status"
                                placeholder={t("STATUS")}
                                optionsValues={isStatus}
                                disabled={isAccountActive ? false : true}
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6} xl={4}>
                            <FormGroup className="mb-3">
                              <Label htmlFor="payee">{t("PAYEE")}</Label>
                              <FormDropdown
                                name="payee"
                                id="payee"
                                placeholder={t("PAYEE")}
                                optionsValues={isPayeeName}
                                disabled={isAccountActive ? false : true}
                              />
                            </FormGroup>
                          </Col>

                          <Col
                            md={12}
                            xl={12}
                            className="d-flex justify-content-end"
                          >
                            <Button
                                disabled={isAccountActive ? false : true}
                                className="btn-secondary w-md"
                                style={{marginRight:'10px'}}
                                type="button"
                                onClick={()=> clearForm(resetForm)}
                            >
                              {t("RESET")}
                            </Button>

                            <Button
                              disabled={isAccountActive ? false : true}
                              className="btn-primary w-md"
                              type="submit"
                            >
                              {t("SEARCH")}
                            </Button>

                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  {/*<MDBDataTable responsive striped bordered data={data} />*/}
                  <div className="custom-table">
                    <MaterialTableCustom
                        data={isAccountActive ? isTableData : []}
                        columns={columns}
                        loading={loading}
                        tableRowViewHandler={isAccountActive ? tableRowViewHandler : null}
                        customAction={
                          (selectedRows) => {

                            return (selectedRows.status === "DISPATCHED" ?
                                {
                                  icon:()=><FileDownloadIcon style={{color:'#757575FF'}}/>,
                                  tooltip: 'Download',
                                  disabled: false,
                                  onClick: (event, selectedRow) => {
                                    downloadConfirmationLetter(selectedRow)
                                  },
                                } : null)

                          }
                        }
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {isOpenPayeeTransactionHistoryModal && (
            <PayeeTransactionEditModal
              rowData={clickedRowData}
              isOpen={isOpenPayeeTransactionHistoryModal}
              toggleModal={toggleTransactionHistoryModal}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PayeeTransactionHistory;
