import React, {useEffect, useState} from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle, Badge, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    DeletePayeeRegister,
    getPayeeRegisterData
} from "../../services/PayeeService";
import {toast} from "react-toastify";
import RegisterPayeeHistory from "./components/registerPayeeHistory";
import MaterialTableCustom from "../../common/tables/MaterialTableCustom";
import {useSelector} from "react-redux";


const Payee = props => {

    const [editTooltip, setEditTooltip] = useState(false);
    const [isOpenPayeeTransactionHistoryModal, setIsOpenPayeeTransactionHistoryModal] = useState(false);
    const [clickedRowData, setClickedRowData] = useState(null);
    const toggleTransactionHistoryModal = () => setIsOpenPayeeTransactionHistoryModal(!isOpenPayeeTransactionHistoryModal);

    const [isDeleteModal, setDeleteModal] = useState(false);
    const deleteToggleModal = () => setDeleteModal(!isDeleteModal);

    const [isDeleteRowData, setDeleteRowData] = useState({});

    const { t } = useTranslation()

    const [loading, setLoading] = useState(false);

    const user = useSelector(state => state.auth.data.userDTO)
    const isAccountActive = user?.isActive

    const [columns, setColumns] = useState([
        { title: t("ACCOUNT_NUMBER"), field: 'accountNumber' ,width: 200,
            render: rowData => (
                <span>{rowData.accountNumber.slice(0, -4)}<span style={{color:'#3558AE'}}>****</span></span>
            ),
        },
        { title: t("ACCOUNT_NAME"), field: 'account_name',width: 200},
        { title: t("PAYEE_NAME"), field: 'payeeName',width: 200},
        { title: t("NICK_NAME"), field: 'nickname',width: 200},
        { title: t("PAYEE_TYPE"), field: 'payeeType',width: 200,
            render: rowData => {
                const badgeColor = rowData.payeeType === "PERSONAL" ? "bg-warning" : "bg-success";
                const badgeText = rowData.payeeType === "PERSONAL" ? "PERSONAL" : "COMPANY";

                return <Badge className={`py-1 ${badgeColor}`}>{badgeText}</Badge>;
            },
        },
        { title: t("TRANSFER_METHOD"), field: 'transferMethod',width: 200},
    ]);

    const [isTableData,setTableData] = useState([])

    const navigate = useNavigate();

    useEffect(()=>{
        fetchPayeeRegisterData()
    },[props.success])


    const fetchPayeeRegisterData = async () =>{

        setLoading(true)

        try {
            const data = {
                co_user_id : user ? user.coUserId : ''

            }
            const response = await getPayeeRegisterData(data)

            const modifiedData = response.result.map(item => ({
                ...item,
                accountNumber: item.account_number,
                payeeName:item.payee_type === "PERSONAL" ? item.first_name || '-' : item.company_name || '-',
                nickname:item.nick_name,
                payeeType:item.payee_type,
                transferMethod:item?.payment_methods[0] === "SWIFT" ? "SWIFT" : (item?.payment_methods[0] === "LOCAL" && item.local_clearing_system === null ) ? "LOCAL" : item.local_clearing_system ? `${item?.payment_methods[0]} - ${item?.local_clearing_system.replace(/_/g, ' ')}` : ""
            }));
            setTableData(modifiedData)
            setLoading(false)
        }catch (error){
            setLoading(false)
           // toast.error(t("ERROR_FETCH_PAYEE_REGISTER_DATA"))
        }
    }


    // table view button action event
    const tableRowEditHandler = (rowData) => {
        setClickedRowData(rowData)
        toggleTransactionHistoryModal();
    };


    const tableRowDeleteHandler = async (rowData) => {
        setDeleteRowData(rowData)
        deleteToggleModal();
    };

    const confirmDelete = async () => {
        let deleteDTO={
            beneficiary_id:isDeleteRowData.beneficiary_id
        }
        try {
             const response = await DeletePayeeRegister(deleteDTO);
            fetchPayeeRegisterData()
            toast.success(t("PAYEE_DELETED_SUCCESSFULLY"));
        } catch (error) {
            if (error.code === "ERR_NETWORK") {
                toast.error(`Error: ${error.message}`);
            } else {
                toast.error(`Error: ${error.response.data.result.error}`);
            }
        } finally {
            deleteToggleModal();
        }
    };

    const clickPayButton=(rowData)=>{
        navigate(`/funds-transfer/registered-payee?payee_id=${rowData.payee_id}`)
    }

    const confirmPayeeTransactionHistory = (rowData) => {
        navigate(`/payee-transaction-history?payee_id=${rowData.payee_id}`)
    };

    document.title = t("PAYEE_TPS_SOLUTIONS");
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="page-title-box p-0">
                        <Row>
                            <Col md={6}>
                                <Breadcrumbs title={t('TPS_SOLUTIONS')}
                                             breadcrumbItem={t('PAYEE')}
                                             mainTitleLink="/" titleLink="#" />
                            </Col>

                            <Col md={6} className="d-flex justify-content-md-end py-3 py-md-4 gap-2">
                                <Link to="/register-payee">
                                    <Button color="primary w-md">
                                        {t("ADD_BENEFICIARY")}
                                    </Button>
                                </Link>
                                <Link to="/payee-transaction-history">
                                    <Button color="primary w-md" >
                                        {t("PAYEE_TRANSACTION")}
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </div>

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4 mb-3">{t("BENEFICIARY_LIST")}</CardTitle>

                                    {/*<MDBDataTable responsive striped bordered data={data} />*/}
                                    <div className="custom-table">
                                        <MaterialTableCustom
                                            data={isAccountActive ? isTableData : []}
                                            columns={columns}
                                            tableRowDeleteHandler={isAccountActive ? tableRowDeleteHandler : null}
                                            loading={loading}
                                            clickPayButton={clickPayButton}
                                            tableRowEditHandler={isAccountActive ? tableRowEditHandler : null}
                                            isPayVisible={isAccountActive ? true : false}
                                            clickHistoryButtion={confirmPayeeTransactionHistory}
                                            isTransActionPayeeVisible={isAccountActive ? true : false}
                                        />
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <>
                    <Modal isOpen={isDeleteModal} centered={true} toggle={deleteToggleModal}>
                        <ModalHeader style={{backgroundColor: 'rgb(3, 35, 112)', color: 'white' }}
                            toggle={null}>{t("CONFIRMATION")}</ModalHeader>
                        <ModalBody>
                            {t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PAYEE")}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={confirmDelete}>
                                {t("YES")}
                            </Button>{' '}
                            <Button color="secondary" onClick={deleteToggleModal}>
                                {t("NO")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </>

                    {isOpenPayeeTransactionHistoryModal && (
                        <RegisterPayeeHistory rowData={clickedRowData} isOpen={isOpenPayeeTransactionHistoryModal} toggleModal={toggleTransactionHistoryModal} fetchPayeeRegisterData={fetchPayeeRegisterData}/>
                    )}

                </Container>
            </div>

        </React.Fragment>
    );
};

export default Payee;
