import { Form, Formik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import React, {useEffect, useState} from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Button, Badge,
} from "reactstrap";
import * as yup from "yup";
import { toast } from "react-toastify";
import moment from "moment";
import FormTextField from "../../common/formComponents/FormTextField";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {
  downloadMonthlyStatement,generateMonthlyStatement,getMonthlyStatement
} from "../../services/ReportService";
import MaterialTableCustom from "../../common/tables/MaterialTableCustom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const MonthlyStatement = () => {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const user = useSelector((state) => state.auth.data?.userDTO);

  const [columns, setColumns] = useState([
    { title: t("REPORT_ID"), field: "reportId", width: 200 },
    { title: t("FILE_NAME"), field: "fileName", width: 200 },
    { title: t("DATE_RANGE"), field: "dateRange", width: 200 },
    { title: t("STATUS"), field: "modifiedStatus", width: 200 },
  ]);

  const validationSchema = yup.object().shape({
    from_created_at: yup
        .date()
        .required("Start date is required")
        .max(new Date(), "Cannot select a future date"),
    to_created_at: yup
        .date()
        .required("End date is required")
  });

  useEffect(() => {
    getAllStatements();
  }, [])

  const getAllStatements = async () => {
    setLoading(true)
    try{
      const response = await getMonthlyStatement(user.coUserId);
      let data = response.result.map((item) => {
          let batchColor = "";
          let batchText = "";
          if(item.status === "COMPLETED"){
            batchColor = "bg-custom-success";
            batchText = "COMPLETED";
          } else if(item.status === "FAILED"){
            batchColor = "bg-danger";
            batchText = "FAILED";
          } else if (item.status === "PENDING"){
            batchColor = "bg-warning";
            batchText = "PENDING";
          }

          let dateRange = `${moment(item.fromCreatedAt).format("DD-MM-YYYY")} - ${moment(item.toCreatedAt).format("DD-MM-YYYY")}`;
          return {
            ...item,
            dateRange: dateRange,
            modifiedStatus: <Badge className={`py-1 ${batchColor}`}>{batchText}</Badge>
          }
      })
      setTableData(data);
    } catch (error) {
      toast.error(t("ERROR_FETCH_MONTHLY_STATEMENT"));
    } finally {
      setLoading(false);
    }

  }

  const handleSubmit = async (values) => {
    const { from_created_at, to_created_at } = values;
    setLoading(true);

    try {

      let data = {
        coUserId: user.coUserId,
        fileFormat: "CSV",
        fileName: `report_${moment().format("YYYYMMDD_HHmmss")}`,
        fromCreatedAt: moment(from_created_at).format("YYYY-MM-DD"),
        toCreatedAt: moment(to_created_at).format("YYYY-MM-DD"),
        type: "TRANSACTION_RECON_REPORT",
      }

      // Call the API to generate the report
      const response = await generateMonthlyStatement(data)
      await getAllStatements();
      const reportId = response.result.id

      // if(reportId) {}
    } catch (error) {
      toast.error("An error occurred while generating the report.");
    } finally {
      setLoading(false);
    }
  };

  const downloadStatement = async (rowData) => {
    setLoading(true)
    try{
      console.log(rowData)
      const data2 = {
        coUserId: user.coUserId,
        reportId: rowData.airwallexReportId
      }

      const response2 = await downloadMonthlyStatement(data2)
      const base64Data = response2.result;
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

// Create a Blob with the correct MIME type for CSV
      const blob = new Blob([byteArray], { type: 'text/csv;charset=utf-8;' });

// Create a URL for the Blob and open it in a new tab
      const blobUrl = URL.createObjectURL(blob);

// Create a temporary link element for downloading
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', `${rowData.fileName}.csv`); // Set the name for the downloaded file

// Append the link to the body, trigger a click, and remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error("An error occurred while downloading the report.");
    } finally {
      setLoading(false)
    }

  }

  document.title = t("MONTHLY_STATEMENT");

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box p-0">
              <Row>
                <Col md={6}>
                  <Breadcrumbs
                      title={t("TPS_SOLUTIONS")}
                      breadcrumbItem={t("MONTHLY_STATEMENT")}
                      mainTitleLink="/"
                      titleLink="#"
                  />
                </Col>
              </Row>
            </div>

            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-3">
                      {t("MONTHLY_STATEMENT")}
                    </CardTitle>

                    <Formik
                        initialValues={{
                          from_created_at: "",
                          to_created_at: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                      {({ values, errors, touched, handleChange, handleSubmit }) => (
                          <form onSubmit={handleSubmit}>
                            <Row >
                              <Col md={4}>
                                <FormTextField
                                    label="From"
                                    name="from_created_at"
                                    type="date"
                                    onChange={handleChange}
                                    value={values.from_created_at}
                                    error={touched.from_created_at && errors.from_created_at}
                                />
                              </Col>

                              <Col md={4}>
                                <FormTextField
                                    label="To"
                                    name="to_created_at"
                                    type="date"
                                    onChange={handleChange}
                                    value={values.to_created_at}
                                    error={touched.to_created_at && errors.to_created_at}
                                />
                              </Col>
                              <Col md={4}>
                                <Button type="submit" color="primary" disabled={loading}>
                                  {loading ? "Generating Report..." : "Generate Report"}
                                </Button>
                              </Col>
                            </Row>
<br/>
                            <Row>
                              <MaterialTableCustom
                                  data={tableData}
                                  columns={columns}
                                  loading={loading}
                                  customAction={
                                    (selectedRows) => {

                                      return (selectedRows.status !== "PENDING" ?
                                          {
                                            icon:()=><FileDownloadIcon style={{color:'#757575FF'}}/>,
                                            tooltip: 'Download',
                                            disabled: false,
                                            onClick: (event, selectedRow) => {
                                              downloadStatement(selectedRow)
                                            },
                                          } : null)

                                    }
                                  }
                              />
                            </Row>
                          </form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

export default MonthlyStatement;
