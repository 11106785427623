import React, { useEffect, useState } from "react";
import AddBox from "@material-ui/icons/AddBox";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Edit from "@material-ui/icons/Edit";
import SaveAlt from "@material-ui/icons/SaveAlt";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Search from "@material-ui/icons/Search";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PaidIcon from '@mui/icons-material/Paid';
import { Grid } from "@material-ui/core";
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';

const MaterialTableCustomSmall = (props) => {
    const tableIcons = {
        Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: React.forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: React.forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: React.forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: React.forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: React.forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: React.forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: React.forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    };

    return (
        <Grid>
            <MaterialTable
                icons={tableIcons}
                isLoading={false}
                columns={props.columns}
                data={props.data}
                components={{
                    Toolbar: (props) => <div style={{ height: '0px' }} />, // Set height to zero
                }}
                actions={[
                    props.isEditVisible && {
                        icon: () => <EditIcon style={{ color: 'green' }} />,
                        tooltip: 'Edit',
                        onClick: (event, selectedRow) => {
                            props.handleRowClick(selectedRow);
                        },
                    },
                    props.isDeleteVisible && {
                        icon: () => <DeleteIcon style={{ color: 'red' }} />,
                        tooltip: 'Delete',
                        onClick: (event, selectedRow) => {
                            props.handleRowDelete(selectedRow);
                        },
                    },
                    props.isPayVisible && {
                        icon: () => <PaidIcon style={{ color: '#3558AE' }} />,
                        tooltip: 'Pay',
                        onClick: (event, selectedRow) => {
                            props.clickPayButton(selectedRow);
                        },
                    },
                    props.isTransActionVisible && {
                        icon: () => <PanToolAltIcon style={{ color: '#757575FF' }} />,
                        tooltip: 'View',
                        onClick: (event, selectedRow) => {
                            props.handleRowClick(selectedRow);
                        },
                    },
                    props.isTransActionPayeeVisible && {
                        icon: () => <PanToolAltIcon style={{ color: '#757575FF' }} />,
                        tooltip: 'History',
                        onClick: (event, selectedRow) => {
                            props.clickHistoryButtion(selectedRow);
                        },
                    }
                ]}
                options={{
                    showTitle: false,
                    search: false, // Remove search
                    paging: false, // Remove pagination controls
                    pageSize: props.pageSize, // Set page size from props
                    actionsColumnIndex: -1,
                    headerStyle: {
                        background: '#032370',
                        color: 'white',
                    },
                }}
            />
        </Grid>
    );
}

export default MaterialTableCustomSmall;
