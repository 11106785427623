import React, {useEffect, useState} from 'react';
import {
    Badge,
    Button, Card, CardBody,
    CardTitle,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

const GlobalAccountModel = props => {
    const {isOpen,toggleModal,rowData,updateData,loading} = props

    const { t } = useTranslation()
    const [isEdit, setIsEdit] = useState(false);
    const [nickName, setNickName] = useState(rowData.nickName);


    const badgeColor =
    rowData.status === "ACTIVE" ? "bg-primary" : ( "INACTIVE" ? "bg-warning" : ( "CLOSED" ? "bg-danger" : "") );
  const badgeText =
    rowData.status === "ACTIVE" ? "Active" : ( "INACTIVE" ? "Inactive" : ( "CLOSED" ? "Close" : "") );

    const enableEdit = () => {
        setIsEdit(true)
    };

    const disableEdit = () => {
        setIsEdit(false)
        setNickName(rowData.nickName)
    };

    const handleUpdate = async () => {
        if(!nickName){
            toast.error(t("NICK_NAME_IS_REQUIRED"))
            return
        }
        await updateData(nickName, rowData.globalAccountId);
        toggleModal();
      };

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggleModal} centered={true} size="lg">
                <ModalHeader style={{backgroundColor: 'rgb(3, 35, 112)', color: 'white' }}
                >{t("GLOBAL_ACCOUNT")} - {rowData?.accountNumber}</ModalHeader>
                <ModalBody>

                    <Row>
                        <Col md={6} xl={6}>
                            <FormGroup className="mb-3">
                                <Label htmlFor="dateAndTime">{t("NICK_NAME")}
                                    <sup className="text-danger"> *</sup>
                                </Label>
                                <Input
                                    name="nickName"
                                    type="text"
                                    className="form-control"
                                    placeholder={t("NICK_NAME")}
                                    id="nickName"
                                    value={nickName}
                                    onChange={(e) => setNickName(e.target.value)}
                                    disabled={!isEdit}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6} xll={6}>
                        <Label htmlFor="dateAndTime">{t("")}
                                </Label>
                                <div className='mt-2'>{
                                isEdit ? <div> <Button color="secondary w-md" onClick={() => handleUpdate()}>{loading ? (
                                    <Spinner
                                      size="sm"
                                      style={{ marginRight: "10px" }}
                                    >
                                      Loading...
                                    </Spinner>
                                  ) : (
                                    ""
                                  )}{" "}{t("SAVE")}</Button> 
                                <Button color="secondary w-md ms-2" onClick={disableEdit}>{loading ? (
                                <Spinner
                                  size="sm"
                                  style={{ marginRight: "10px" }}
                                >
                                  Loading...
                                </Spinner>
                              ) : (
                                ""
                              )}{" "}{t("CANCEL")}</Button></div>  : 
                                <Button color="secondary w-md" onClick={enableEdit}>{loading ? (
                                    <Spinner
                                      size="sm"
                                      style={{ marginRight: "10px" }}
                                    >
                                      Loading...
                                    </Spinner>
                                  ) : (
                                    ""
                                  )}{" "}{t("EDIT")}</Button>
                             }</div>
                        </Col>
                    </Row>
                            <Row>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="dateAndTime">{t("ACCOUNT_NAME")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="accountName"
                                            type="text"
                                            className="form-control"
                                            placeholder={t("ACCOUNT_NAME")}
                                            id="accountName"
                                            value={rowData.accountName}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="paidAmount">{t("BANK_ACCOUNT_NUMBER")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="accountNumber"
                                            placeholder={t("BANK_ACCOUNT_NUMBER")}
                                            type="text"
                                            className="form-control"
                                            id="accountNumber"
                                            value={rowData.accountNumber}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="paidBy">{t("BANK_CODE")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="bankCode"
                                            // placeholder="Paid By"
                                            type="text"
                                            className="form-control"
                                            id="bankCode"
                                            value={rowData.bankCode}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="transferRate">{t("BRANCH_CODE")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="branchCode"
                                            // placeholder="Transfer Rate"
                                            type="text"
                                            className="form-control"
                                            id="branchCode"
                                            value={rowData.branchCode}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="transferRate">{t("SORT_CODE")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="sortCode"
                                            // placeholder="Transfer Rate"
                                            type="text"
                                            className="form-control"
                                            id="sortCode"
                                            value={rowData.sortCode}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="institutionName">{t("BANK_NAME")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="institutionName"
                                            // placeholder="Payee Name"
                                            type="text"
                                            className="form-control"
                                            id="institutionName"
                                            value={rowData.institutionName}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="countryCode">{t("ACCOUNT_LOCATION")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="countryCode"
                                            // placeholder="Transferred Amount"
                                            type="text"
                                            className="form-control"
                                            id="countryCode"
                                            value={rowData.countryCode}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="institutionAddress">{t("BANK_ADDRESS")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="institutionAddress"
                                            // placeholder="Account Number"
                                            type="text"
                                            className="form-control"
                                            id="institutionAddress"
                                            value={rowData.institutionAddress}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="institutionCity">{t("CITY")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="institutionCity"
                                            // placeholder="Transferred Mode"
                                            type="text"
                                            className="form-control"
                                            id="institutionCity"
                                            value={rowData.institutionCity}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="institutionZipCode">{t("POSTAL_CODE")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="institutionZipCode"
                                            // placeholder="Bank Country"
                                            type="text"
                                            className="form-control"
                                            id="institutionZipCode"
                                            value={rowData.institutionZipCode}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="status">{t("STATUS")}

                                        </Label>
                                        <br/>
                                        <Badge className={`font-size-14 p-2 ${badgeColor}`}>{badgeText}</Badge>
                                        {/* <Input
                                            name="status"
                                            // placeholder="Purpose"
                                            type="text"
                                            className="form-control"
                                            id="status"
                                            value={rowData.status}
                                            disabled={true}
                                        /> */}
                                    </FormGroup>
                                </Col>

                            </Row>


                </ModalBody>
                <ModalFooter>
                    <Button color="secondary w-md" onClick={toggleModal}>{t("CLOSE")}</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default GlobalAccountModel;