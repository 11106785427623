import React, { useEffect, useState, useRef } from 'react';
import {useSelector} from "react-redux";
import {
  Container,
  Card,
  Spinner,
  Button,
  Row,
  Col,
  CardBody, ModalHeader, Modal, ModalBody,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Base64 } from "js-base64";
import { init, createElement } from '@airwallex/payouts-web-sdk';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useNavigate } from "react-router-dom";
import { postAirwallex, axiosApiAirwallex } from "../../helpers/api_helper";
import {toast} from "react-toastify";
import {PayeeRegister, savePayee} from "../../services/PayeeService";

const RegisternewPayee = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const beneficiaryFormRef = useRef(null);
  const beneficiaryComponentElementRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [countries, setCountries] = useState([
    "Australia", "Bangladesh", "China", "Hong Kong SAR", "India", "Indonesia", "Japan",
    "Korea, Republic of (South Korea)", "Malaysia", "Nepal", "New Zealand", "Pakistan",
    "Philippines", "Singapore", "Sri Lanka", "Thailand", "Turkey", "Viet Nam",
    "Middle East", "Bahrain", "Israel", "United Arab Emirates",
    "Europe", "Austria", "Belgium", "Bulgaria", "Croatia", "Cyprus", "Czech Republic",
    "Denmark", "Estonia", "Finland", "France", "Germany", "Greece", "Hungary", "Iceland",
    "Ireland", "Italy", "Latvia", "Liechtenstein", "Lithuania", "Luxembourg", "Malta",
    "Martinique", "Mayotte", "Monaco", "Netherlands", "Norway", "Poland", "Portugal",
    "Romania", "Saint Pierre and Miquelon", "San Marino", "Slovakia", "Slovenia", "Spain",
    "Sweden", "Switzerland", "United Kingdom",
    "Americas", "Argentina", "Bolivia", "Brazil", "Canada", "Chile", "Colombia", "Mexico",
    "Peru", "Paraguay", "United States", "Uruguay",
    "Africa", "Angola", "Benin", "Botswana", "Burkina Faso", "Cameroon", "Central African Republic",
    "Chad", "Côte d'Ivoire", "Egypt", "Equatorial Guinea", "Gabon", "Gambia", "Guinea-Bissau",
    "Kenya", "Lesotho", "Madagascar", "Malawi", "Morocco", "Namibia", "Niger", "Nigeria",
    "Republic of the Congo", "Rwanda", "Senegal", "Togo", "Zambia"
  ]);

  const filteredCountries = countries.filter(country =>
      country.toLowerCase().includes(searchQuery.toLowerCase()) // Filter countries based on search query
  );

  const user = useSelector(state => state.auth.data.userDTO)
  const isAccountActive = user?.isActive

  const { t } = useTranslation();

  const generateCodeVerifier = async () => {
    const length = Math.floor(Math.random() * (129 - 43)) + 43;
    const array = new Uint32Array(length / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ("0" + dec.toString(16)).slice(-2)).join("");
  };

  const generateCodeChallengeFromVerifier = async (verifier) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(verifier);
    const hashed = await window.crypto.subtle.digest("SHA-256", data);
    const bytes = new Uint8Array(hashed);
    return Base64.fromUint8Array(bytes, true);
  };

  const fetchAuthorizationCode = async (codeChallenge) => {
    try {
      const response = await postAirwallex("authentication/authorize", {
        code_challenge: codeChallenge,
        scope: ["w:awx_action:transfers_edit"],
      });
      return response.authorization_code;
    } catch (error) {
      console.error("Error fetching authorization code", error);
    }
  };

  const fetchAuthToken = async () => {
    try {
      const { token } = await postAirwallex("authentication/login", []);
      axiosApiAirwallex.defaults.headers['Authorization'] = `Bearer ${token}`;
      localStorage.setItem("airWallexToken", JSON.stringify(token));
      return token;
    } catch (error) {
      console.error("Error fetching auth token", error);
    }
  };



  const initAndRenderElement = async () => {
    try {

      await fetchAuthToken();
      const condeVerifier = await generateCodeVerifier();
      const codeChallenge = await generateCodeChallengeFromVerifier(condeVerifier);
      const authCode = await fetchAuthorizationCode(codeChallenge);

      const options = {
        langKey: "en",
        env: process.env.REACT_APP_AIRWALLEX_ENV,
        authCode: authCode,
        clientId: process.env.REACT_APP_AIRWALLEX_CLIENT_ID,
        codeVerifier: condeVerifier,
      };
      console.log("options", options)
      await init(options);

      const Customizations = {
        fields: {
          source_currency: {
            disabled: true
          }
        }
      }
      
 
      const beneficiaryComponentElement = await createElement('beneficiaryForm', {
        customizations: {
          fields: {
            source_currency: {
              disabled: true,
            }
          },
        }
      });
      beneficiaryComponentElementRef.current = beneficiaryComponentElement;
      // console.log("beneficiaryComponentlement", beneficiaryComponentElement)
      if (beneficiaryFormRef.current) {
        beneficiaryComponentElement.mount(beneficiaryFormRef.current); // Mount to the ref
      } 

      beneficiaryComponentElement.on('ready', () => {
        console.log("ready")
        setIsReady(true);
       // Handle after ready
      });
      
      beneficiaryComponentElement.on('change', ({value}) => {
        // console.log("change", value)
        // Handle when form value changes
      });
      
      beneficiaryComponentElement.on('formState', ({loading, validating, errors}) => {
        // console.log("formState", loading, validating, errors)
        // Handle when form state changes
      });

      setLoading(false);
    } catch (error) {
      console.error("Error initializing and rendering the element", error);
      toast.error('Network Error')
      // setTimeout(() => {
      //   window.location.reload()
      // }, 3000);
    }
  };

  const handleSubmit = async () => {
    if (beneficiaryComponentElementRef.current) {
      setBtnLoading(true)
      const formResult = await beneficiaryComponentElementRef.current.submit();
      console.log("formResult", formResult);
      const entityType = formResult?.values?.beneficiary?.entity_type || null

      if (!formResult.errors) {
        try {
          console.log("here");
          const response = await savePayee(formResult.values, user.coUserId)
          console.log("response", response)
          setBtnLoading(false)

          if(entityType === "PERSONAL") {
            toast.success(t("REGISTER_INDIVIDUAL_PAYEE_SUCCESS"))
          } else if (entityType === "COMPANY") {
            toast.success(t("REGISTER_BUSINESS_PAYEE_SUCCESS"))
          } else {
            toast.success(t("REGISTER_PAYEE_SUCCESS"))
          }

          navigate('/payee')
        } catch (error) {
          if(entityType === "PERSONAL") {
            toast.error(t("REGISTER_INDIVIDUAL_PAYEE_ERROR"))
          } else if (entityType === "COMPANY") {
            toast.error(t("REGISTER_BUSINESS_PAYEE_ERROR"))
          } else {
            toast.error(t("REGISTER_PAYEE_ERROR"))
          }

        }
      } else {
        if(entityType === "PERSONAL") {
          toast.error(t("REGISTER_INDIVIDUAL_PAYEE_ERROR"))
        } else if (entityType === "COMPANY") {
          toast.error(t("REGISTER_BUSINESS_PAYEE_ERROR"))
        } else {
          toast.error(t("REGISTER_PAYEE_ERROR"))
        }
      }
      setBtnLoading(false)
    }
  };

  const handleCancel = () => {
    navigate('/payee')
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Update search query as user types
  };
  
  useEffect(() => {
      initAndRenderElement();
  }, []);

  document.title = t("REGISTER_PAYEE_TPS_SOLUTIONS");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            maintitle={t('TPS_SOLUTIONS')}
            title={t('PAYEE')}
            breadcrumbItem={t("REGISTER_PAYEE")}
            mainTitleLink="/"
            titleLink="/payee"
          />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="text-end">
                    <Button
                        className="btn-info w-md"
                        onClick={toggleModal}
                    >
                      {t("Local Payment Enabled Countries")}
                    </Button>
                  </div>
                  {loading ? (
                      <div className="text-center">
                        <Spinner color="primary"/>
                      </div>
                  ) : (
                    <div>
                        <div ref={beneficiaryFormRef} id="beneficiary-form-container" />
                        {
                          isReady ? (
                            <div className='d-flex justify-content-end gap-3 mt-3'>
                              <Button className="btn-secondary w-md"
                                type="button" onClick={handleCancel}>{t("BACK")}</Button>
                              <Button className="btn-primary w-md" onClick={handleSubmit}
                                type="submit">
                                {btnLoading ? (
                                  <Spinner size="sm">
                                    Loading...
                                  </Spinner>
                                ) : ""}
                                {' '}{' '}{t("SUBMIT")}</Button>
                            </div>
                          ) : (''
                          )
                        }
                      </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={isModalOpen} toggle={toggleModal} centered>
            <ModalHeader style={{ backgroundColor: 'rgb(3, 35, 112)', color: 'white' }} toggle={toggleModal}>
              {t("Local Payment Enabled Countries")}
            </ModalHeader>
            <ModalBody>
              <div>
                <input
                    type="text"
                    placeholder={t("Search...")}
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="form-control mb-3"
                />
              </div>
              <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                    gap: '10px',
                    padding: '10px',
                  }}
              >
                {filteredCountries.map((country, index) => (
                    <div
                        key={index}
                        style={{
                          backgroundColor: 'rgb(240, 240, 240)',
                          borderRadius: '5px',
                          padding: '10px',
                          textAlign: 'center',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                          fontWeight: 'bold',
                          fontSize: '18px', // Increase font size
                          color: '#333',
                        }}
                    >
                      {country}
                    </div>
                ))}
              </div>
            </ModalBody>
          </Modal>



        </Container>
      </div>
    </React.Fragment>
  );
};

export default RegisternewPayee;
