import FormTextField from "../../common/formComponents/FormTextField";
import React, { useEffect, useRef, useState } from "react";

import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";

import { Formik, Form } from "formik";
import * as yup from "yup";
import FormDropdown from "../../common/formComponents/FormDropdown";
import {
  SignUpRegister,
  sendOtpToPhnNumber,
  oTPVerification,
  SignUpUserValidate,
  sendOtpToPhnNumberAndVerify,
} from "../../services/AuthService";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import FormPhoneNumberField from "../../common/formComponents/FormPhoneNumberField";
import { useNavigate } from "react-router-dom";
import loginBg from "../../assets/images/login/auth-bg.jpeg";

const SignUpPage = (props) => {
  // Create an array to store refs for each input
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const { registrationData, setUserId, setactiveTab ,signUpUser} = props;
  const { t } = useTranslation();
  const { countries, setSignUpUser } = props;
  const navigate = useNavigate()

  const [resultId, setResultId] = useState(null);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [isOtpWrong, setOtpWrong] = useState(false);
  const [otpVerified, setOptVerified] = useState(signUpUser?.verifiedMobile ? true : false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [isSendOtpBtnShow, setIsSendOtpBtnShow] = useState(true);
  const [otps, setOtps] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });

  const [disabledPhoneNumberField, setDisabledPhoneNumberField] =
    useState(false);
  const [disabledOtpBtn, setDisabledOtpBtn] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isOtpButtonText, setOtpButtonText] = useState(t("SEND_OTP"));
  const [isOtpButtonColor, setOtpButtonColor] = useState("btn-primary");
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);

  let timerInterval;

  const resetTimer = () => {
    clearInterval(timerInterval);
    setTimer(60);
    timerInterval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
  };

  useEffect(() => {
    if (submit === true) {
      setLoading(false);
      setactiveTab(2);
    } else {
      setLoading(false);
      setactiveTab(1);
    }
  }, [submit]);

  // Countdown timer logic
  useEffect(() => {
    if (showOtpInput && timer > 0) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(timerInterval);
      setShowOtpInput(false);
      setTimer(60);
      setIsSendOtpBtnShow(true);
      setOtpButtonText(t("RESEND_OTP"));
      setOtpButtonColor("glass-warning-btn");
      setOptVerified(false);
      // resetDataOtpFields()
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [showOtpInput, timer]);

  const sendOptHandler = async (value) => {
    if (value.length > 7) {
      try {
        const data = {
          expireIn: 500,
          messageId: null,
          messageText: null,
          messageType: "OTP",
          recipientPhoneNumber: value,
          senderId: null,
          senderPhoneNumber: null,
          status: null,
          otpType: "SMS"
        };

        const response = await sendOtpToPhnNumberAndVerify(data);
        setResultId(response.result);

        setIsSendOtpBtnShow(false);
        setShowOtpInput(true);
      } catch (error) {
        if(error?.response?.data?.result?.error){
          toast.error(error?.response?.data?.result?.error);
        }else{
          toast.error(t("ERROR_SEND_PHONE_NUMBER"));
        }
      }
    } else {
      toast.error(t("PHONE_IS_REQUIRED"));
    }
  };

  const handleVerifyClick = async (value) => {
    try {
      const data = {
        massageId: resultId,
        otpCode: `${otps.otp1}${otps.otp2}${otps.otp3}${otps.otp4}${otps.otp5}${otps.otp6}`,
      };

      const response = await oTPVerification(data);

      if (response.statusMessage === "Success") {
        setShowOtpInput(false);
        setOtpButtonText(t("SUCCESS"));
        setDisabledOtpBtn(true);
        setOtpButtonColor("glass-succes-btn");
        setIsSendOtpBtnShow(true);
        setDisabledPhoneNumberField(true);

        resetTimer();
        setOtps({
          otp1: "",
          otp2: "",
          otp3: "",
          otp4: "",
          otp5: "",
          otp6: "",
        });
        setOptVerified(true);
        toast.success(t("VERIFY_SUCCESS"));
      } else {
        setOtpWrong(true);
        setOptVerified(false);
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        toast.error(`Error: ${error.message}`);
      } else {
        setOtpWrong(true);
        setOptVerified(false);
        toast.error(`Error: ${error.response.data.result.error}`);
      }
    }
  };

  const handleConfirm = () => {
    setIsCancelModal(false);
    navigate('/login')
  };
  const handleShowModal = () => {
    setIsCancelModal(true);
  };

  const handleCancel = () => {
    setIsCancelModal(false);
  };

  const onSubmit = async (values) => {
    if (otpVerified === true) {
      const data = {
        businessEmail: values.businessEmail,
        country: values.country,
        firstName: values.firstName,
        lastName: values.lastName,
        mobileNumber: values.phone,
        verifiedMobile: true,
        authorizeEmail: values.authorizeEmail
      };

    //   try {
    //     setLoading(true);
    //     setSignUpUser(data);
    //     const response = await SignUpRegister(data);
    //     toast.success(t("REGISTRATION_SUCCESS"));
    //     setUserId(response.result);
    //     setSubmit(true);
    //   } catch (error) {
    //     setLoading(false);

    //     //toast.error(t("ERROR_REGISTRATION"));
    //     if (error.code === "ERR_NETWORK") {
    //       toast.error(`Error: ${error.message}`);
    //     } else {
    //       toast.error(`Error: ${error.response.data.result.error}`);
    //     }
    //     alert("error");
    //     setSubmit(false);
    //     setactiveTab(1);
    //   }


      setLoading(true);
      try {
        setSignUpUser(data);
        const response = await SignUpUserValidate(data);
        //toast.success(t("SUCCESS"));
        // setUserId(response.result);
        setLoading(false);
        setSubmit(true);
        setactiveTab(2);
      } catch (error) {
        setLoading(false);

        //toast.error(t("ERROR_REGISTRATION"));
        if (error.code === "ERR_NETWORK") {
          toast.error(`Error: ${error.message}`);
        } else {
          toast.error(`Error: ${error.response.data.result.error}`);
        }
        setSubmit(false);
        setactiveTab(1);
      }


    } else {
      toast.error("Complete the Mobile Verification");

    }
  };

  const validationSchema = yup.object().shape({
    country: yup.string().required(t("COUNTRY_IS_REQUIRED")),
    firstName: yup.string().required(t("FIRST_NAME_IS_REQUIRED")),
    lastName: yup.string().required(t("LAST_NAME_IS_REQUIRED")),
    phone: yup.string().required(t("PHONE_NUMBER_IS_REQUIRED")),
    businessEmail: yup
      .string()
      .email(t("EMAIL_IS_REQUIRED"))
      .required(t("EMAIL_IS_REQUIRED")),
    authorizeEmail: yup
      .string()
      .email(t("EMAIL_IS_REQUIRED"))
      .required(t("EMAIL_IS_REQUIRED")),
  });

  const handleInputChange = (index, value) => {
    // Move to the next input when a value is entered
    if (value.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };


  document.title = `${t("SIGN_UP")} | TPS ${t("SOLUTIONS")}`

  return (
    <React.Fragment>


          <Row className="mt-3 mb-3">
            <Col md={12}>
              <h4 className="text-white">{t("REGISTER")}</h4>
            </Col>
          </Row>

          <Modal isOpen={isCancelModal} centered={true}>
            <ModalBody className="auth-bg overflow-hidden">
              <img className="auth-bg-img" src={loginBg} alt="login-bg"/>
              <Row className="position-relative z-3">
                <Col md={12} className="text-center py-4 mb-3">
                  <h5 className="mb-0 text-white">
                    {t("DO_YOU_WANT_TO_CANCEL_THE_SIGN_UP_PROCESS?")}
                  </h5>
                </Col>

                <Col
                  md={12}
                  className="d-flex justify-content-center align-items-center gap-4"
                >
                  <Button color="primary w-md" className="glass-btn" onClick={handleConfirm}>
                    {t("YES")}
                  </Button>{" "}
                  <Button color="secondary w-md" onClick={handleCancel}>
                    {t("NO")}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
          <Formik
            enableReinitialize
            initialValues={{
              country: signUpUser
                ? signUpUser?.country
                : "",
              brNumber: signUpUser
                ? signUpUser?.brNumber
                : "",
              firstName: signUpUser
                ? signUpUser?.firstName
                : "",
              lastName: signUpUser
                ? signUpUser?.lastName
                : "",
              businessEmail: signUpUser
                ? signUpUser?.businessEmail
                : "",
              authorizeEmail : signUpUser
                ? signUpUser?.authorizeEmail
                : "",
              title: signUpUser
                ? signUpUser?.title
                : "",
              phone: signUpUser
                  ? signUpUser?.mobileNumber : "",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values }) => (
              <Form>
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-3 text-white-70">
                      <Label htmlFor="firstName">{t("FIRST_NAME")}
                        <sup
                            className="text-danger"> *</sup>
                      </Label>
                      <FormTextField
                        name="firstName"
                        id="firstName"
                        placeholder={t("FIRST_NAME")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3 text-white-70">
                      <Label htmlFor="lastName">{t("LAST_NAME")}
                        <sup
                            className="text-danger"> *</sup>
                      </Label>
                      <FormTextField
                        name="lastName"
                        id="lastName"
                        placeholder={t("LAST_NAME")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3 text-white-70">
                      <Label htmlFor="businessEmail">
                        {t("Email")}
                        <sup
                            className="text-danger"> *</sup>
                      </Label>
                      <FormTextField
                        name="businessEmail"
                        id="businessEmail"
                        placeholder={t("Email")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3 text-white-70">
                      <Label htmlFor="authorizeEmail">
                        {t("AUTHORIZATION_EMAIL")}
                        <sup
                            className="text-danger"> *</sup>
                      </Label>
                      <FormTextField
                        name="authorizeEmail"
                        id="authorizeEmail"
                        placeholder={t("AUTHORIZATION_EMAIL")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3 text-white-70">
                      <Label htmlFor="country">{t("COUNTRY")}
                        <sup
                            className="text-danger"> *</sup>
                      </Label>
                      <FormDropdown
                        name="country"
                        id="country"
                        placeholder={t("SELECT_COUNTRY")}
                        optionsValues={countries}
                      />
                    </FormGroup>
                  </Col>
                  {showOtpInput && <Col md={6}></Col>}
                  <Col md={6}>
                    <FormGroup className="align-items-center gap-3 text-white-70">
                      <Label className="text-md-start" htmlFor="phone">
                        {t("WHAT_IS_YOUR_PHONE_NUMBER?")}
                        <sup
                            className="text-danger"> *</sup>
                      </Label>
                      <div className="d-flex align-items-start gap-2 gap-sm-3 auth-phone-input-content">
                        <FormPhoneNumberField
                          name="phone"
                          id="phone"
                          disabled={disabledPhoneNumberField}
                          // label={t("PHONE_NUMBER")}
                        />

                        {isSendOtpBtnShow ? (
                          <Button
                            className={`btn ${isOtpButtonColor} glass-btn w-md waves-effect waves-light`}
                            type="button"
                            disabled={disabledOtpBtn}
                            onClick={() => {
                              sendOptHandler(values.phone);
                            }}
                          >
                            {isOtpButtonText}
                          </Button>
                        ) : ""}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    {showOtpInput && (
                      <Row md={12}>
                        <Col md={12} lg={12}>
                          <FormGroup className="text-center text-md-start">
                            <Label className="text-md-start text-white-70" htmlFor="otp">
                              {t(
                                "WE_SEND_YOU_A_CODE_TO_VERIFY_YOUR_CANTACT_NUMBER"
                              )}
                            </Label>
                            <div className="d-flex justify-content-center justify-content-md-start gap-2 gap-lg-3">
                              <input
                                key={0}
                                ref={inputRefs[0]}
                                name="otp1"
                                className="form-control text-center max-w-35"
                                placeholder=""
                                type="text"
                                id="otp1"
                                maxLength={1}
                                value={otps.otp1}
                                onChange={(e) => {
                                  setOtps({
                                    ...otps,
                                    otp1: e.target.value,
                                  });
                                  handleInputChange(0, e.target.value);
                                }}
                              />

                              <input
                                key={1}
                                ref={inputRefs[1]}
                                name="otp2"
                                className="form-control text-center max-w-35"
                                placeholder=""
                                type="text"
                                id="otp2"
                                maxLength={1}
                                value={otps.otp2}
                                onChange={(e) => {
                                  setOtps({
                                    ...otps,
                                    otp2: e.target.value,
                                  });
                                  handleInputChange(1, e.target.value);
                                }}
                              />

                              <input
                                key={2}
                                ref={inputRefs[2]}
                                name="otp3"
                                className="form-control text-center max-w-35"
                                placeholder=""
                                type="text"
                                id="otp3"
                                maxLength={1}
                                value={otps.otp3}
                                onChange={(e) => {
                                  setOtps({
                                    ...otps,
                                    otp3: e.target.value,
                                  });
                                  handleInputChange(2, e.target.value);
                                }}
                              />

                              <input
                                key={3}
                                ref={inputRefs[3]}
                                name="otp4"
                                className="form-control text-center max-w-35"
                                placeholder=""
                                type="text"
                                id="otp4"
                                maxLength={1}
                                value={otps.otp4}
                                onChange={(e) => {
                                  setOtps({
                                    ...otps,
                                    otp4: e.target.value,
                                  });
                                  handleInputChange(3, e.target.value);
                                }}
                              />

                              <input
                                key={4}
                                ref={inputRefs[4]}
                                name="otp5"
                                className="form-control text-center max-w-35"
                                placeholder=""
                                type="text"
                                id="otp5"
                                maxLength={1}
                                value={otps.otp5}
                                onChange={(e) => {
                                  setOtps({
                                    ...otps,
                                    otp5: e.target.value,
                                  });
                                  handleInputChange(4, e.target.value);
                                }}
                              />

                              <input
                                key={5}
                                ref={inputRefs[5]}
                                name="otp6"
                                className="form-control text-center max-w-35"
                                placeholder=""
                                type="text"
                                id="otp6"
                                maxLength={1}
                                value={otps.otp6}
                                onChange={(e) => {
                                  setOtps({
                                    ...otps,
                                    otp6: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <p className="mt-2 mb-0 font-size-12 lh-1 text-white-70">
                              {t("YOU_WILL_RECEIVE_YOUR_CODE_IN")}{" "}
                              <span className="text-white">{timer}</span>
                              {isOtpWrong ? (
                                <>
                                  {" | "}{" "}
                                  <span style={{ color: "red" }}>
                                    {t("VERIFICATION_CODE_IS_WRONG")}
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}

                    {isSendOtpBtnShow ? 
                      ""
                    : (
                      <Button
                        className="btn glass-btn w-md waves-effect waves-light"
                        type="button"
                        onClick={() => {
                          setOtpWrong(false);
                          handleVerifyClick(values.phone);
                        }}
                      >
                        {t("VERIFY")}{" "}
                        <Spinner size="sm" className="text-primary">
                          {t("LOADING")}...
                        </Spinner>
                      </Button>
                    )}
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={12} className="d-flex justify-content-end gap-3">
                    <Button onClick={handleShowModal} color="secondary w-md">
                      {t("CANCEL")}
                    </Button>
                    <>
                      <Button  type="submit" color="primary w-md" className="glass-btn">
                        { loading ? (
                            <Spinner size="sm">
                              Loading...
                            </Spinner>
                        ) : "" }

                        {' '}{' '}{t("SAVE_AND_NEXT")}
                      </Button>{" "}
                    </>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
    </React.Fragment>
  );
};

export default SignUpPage;
