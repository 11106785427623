import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";

import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as yup from "yup";
import FormDropdown from "../../common/formComponents/FormDropdown";
import FormTextField from "../../common/formComponents/FormTextField";
import {Link, useNavigate} from "react-router-dom";
import { saveSecurityQuestionsFirstTime } from "../../services/Profile";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SignUpRegister } from "../../services/AuthService";
import loginBg from "../../assets/images/login/auth-bg.jpeg";

const SecurityQuestion = (props) => {

  const { questions, signUpUser } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false)
  const [quations1, setQuestions1] = useState(questions)
  const [quations2, setQuestions2] = useState(questions)
  const [quations3, setQuestions3] = useState(questions)

  const user = useSelector((state) => state.auth.data?.userDTO);

  const REGISTRATION_ACCOUNT_FEE = process.env.REACT_APP_REGISTRATION_ACCOUNT_FEE

  const handleCancel = () => {
    props.setactiveTab(1)
  };

  const validationSchema = yup.object().shape({
    questionOne: yup.string().required(t("QUESTION_ONE_IS_REQUIRED")),
    answerOne: yup.string().required(t("ANSWER_ONE_IS_REQUIRED")),
    questionTwo: yup.string().required(t("QUESTION_TWO_IS_REQUIRED")),
    answerTwo: yup.string().required(t("ANSWER_TWO_IS_REQUIRED")),
    questionThree: yup.string().required(t("QUESTION_THREE_IS_REQUIRED")),
    answerThree: yup.string().required(t("ANSWER_THREE_IS_REQUIRED")),
  });

  useEffect(() => {
    // console.log(props.registrationData);
    // console.log(props.userId);
  }, [props.success]);

  const onSubmit = async (values) => {
    setLoading(true)
    const dataArray = [
      {
        questionText: "string",
        answerId: null,
        questionId: values.questionOne,
        answerText: values.answerOne,
        // coUserId: props.userId,
      },
      {
        questionText: "string",
        answerId: null,
        questionId: values.questionTwo,
        answerText: values.answerTwo,
        // coUserId: props.userId,
      },
      {
        questionText: "string",
        answerId: null,
        questionId: values.questionThree,
        answerText: values.answerThree,
        // coUserId: props.userId,
      },
    ];

    const data = {
      businessEmail: signUpUser.businessEmail,
      country: signUpUser.country,
      firstName: signUpUser.firstName,
      lastName: signUpUser.lastName,
      mobileNumber: signUpUser.mobileNumber,
      verifiedMobile:signUpUser.verifiedMobile,
      authorizeEmail: signUpUser.authorizeEmail,
      securityQuestionAnswer: dataArray,
    };

    try {
      const response = await SignUpRegister(data)
      setLoading(false)
      navigate("/thank-you", { state: { data: data, success: true } });
      // props.setactiveTab(3);
      // modalPopup();
    } catch (error) {
      setLoading(false)
      if(error?.response?.data?.result?.error[0]){
       // console.log(error?.response?.data?.result?.error[0]);
        toast.error(`Error: ${error?.response?.data?.result?.error[0]}`);
      }else{
        toast.error(t("ERROR_SECURITY_QUESTION"));
      }
    }
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const modalPopup = () => {
    setModal(true);
  };

  const modalClose = () => {
    setModal(false);
    navigate("/login");
  };

  const changeDropdown1 = (value, form) => {

    form.setFieldValue("questionOne",value)
    const restQuations1 = []
    const restQuations2 = []
    const restQuations3 = []

    questions.forEach((q) => {
      if(! ((form.values?.questionTwo && q.value == form.values?.questionTwo) || (form.values?.questionThree && q.value == form.values?.questionThree))){
        restQuations1.push(q)
      }
    })

     questions.forEach((q) => {
      if(!(q.value == value || (form.values?.questionThree && q.value == form.values?.questionThree))){
        restQuations2.push(q)
      }
    })

    questions.forEach((q) => {
      if(!(q.value == value || (form.values?.questionTwo && q.value == form.values?.questionTwo))){
        restQuations3.push(q)
      }
    })

    setQuestions1(restQuations1)
    setQuestions2(restQuations2)
    setQuestions3(restQuations3)
  }

  const changeDropdown2 = (value, form) => {

    form.setFieldValue("questionTwo",value)
    const restQuations1 = []
    const restQuations2 = []
    const restQuations3 = []


    questions.forEach((q) => {
      if(! ( q.value == value || (form.values?.questionThree && q.value == form.values?.questionThree))){
        restQuations1.push(q)
      }
    })

     questions.forEach((q) => {
      if(!((form.values?.questionOne && q.value == form.values?.questionOne) || (form.values?.questionThree && q.value == form.values?.questionThree))){
        restQuations2.push(q)
      }
    })

    questions.forEach((q) => {
      if(!(q.value == value || (form.values?.questionOne && q.value == form.values?.questionOne))){
        restQuations3.push(q)
      }
    })

    setQuestions1(restQuations1)
    setQuestions2(restQuations2)
    setQuestions3(restQuations3)
  }

  const changeDropdown3 = (value, form) => {
    form.setFieldValue("questionThree",value)
    const restQuations1 = []
    const restQuations2 = []
    const restQuations3 = []

    questions.forEach((q) => {
      if(! ( q.value == value || (form.values?.questionTwo && q.value == form.values?.questionTwo))){
        restQuations1.push(q)
      }
    })

     questions.forEach((q) => {
      if(! (q.value == value  || (form.values?.questionOne && q.value == form.values?.questionOne))){
        restQuations2.push(q)
      }
    })

    questions.forEach((q) => {
      if(!((form.values?.questionOne && q.value == form.values?.questionOne) || (form.values?.questionTwo && q.value == form.values?.questionTwo))){
        restQuations3.push(q)
      }
    })


    setQuestions1(restQuations1)
    setQuestions2(restQuations2)
    setQuestions3(restQuations3)
  }

  document.title = `${t("SIGN_UP")} | TPS ${t("SOLUTIONS")}`

  return (
    <React.Fragment>
      <Row className="mt-3 mb-3">
        <Col md={12}>
          <h4 className="text-white">{t("SECURITY_QUESTION")}</h4>
        </Col>
      </Row>

      <Formik
        enableReinitialize
        initialValues={{
          answerThree: "",
          questionThree: "",
          answerTwo: "",
          questionTwo: "",
          answerOne: "",
          questionOne: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Row>
              <Col md={6}>
                <FormGroup className="mb-3 text-white-70">
                  <Label htmlFor="questionOne">
                    {t("QUESTION")} 01 <sup className="text-danger"> *</sup>
                  </Label>
                  <FormDropdown
                    name="questionOne"
                    id="questionOne"
                    placeholder={`${t("QUESTION")} 01`}
                    optionsValues={quations1}
                    changeDropdown={changeDropdown1}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-3 text-white-70">
                  <Label htmlFor="answerOne">
                    {t("ANSWER")} 01 <sup className="text-danger"> *</sup>
                  </Label>
                  <FormTextField
                    name="answerOne"
                    id="answerOne"
                    placeholder={`${t("ANSWER")} 01`}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-3 text-white-70">
                  <Label htmlFor="questionTwo">
                    {t("QUESTION")} 02 <sup className="text-danger"> *</sup>
                  </Label>
                  <FormDropdown
                    name="questionTwo"
                    id="questionTwo"
                    placeholder={`${t("QUESTION")} 02`}
                    optionsValues={quations2}
                    changeDropdown={changeDropdown2}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-3 text-white-70">
                  <Label htmlFor="answerTwo">
                    {t("ANSWER")} 02 <sup className="text-danger"> *</sup>
                  </Label>
                  <FormTextField
                    name="answerTwo"
                    id="answerTwo"
                    placeholder={`${t("ANSWER")} 02`}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-3 text-white-70">
                  <Label htmlFor="questionThree">
                    {t("QUESTION")} 03 <sup className="text-danger"> *</sup>
                  </Label>
                  <FormDropdown
                    name="questionThree"
                    id="questionThree"
                    placeholder={`${t("QUESTION")} 03`}
                    optionsValues={quations3}
                    changeDropdown={changeDropdown3}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-3 text-white-70">
                  <Label htmlFor="answerThree">
                    {t("ANSWER")} 03 <sup className="text-danger"> *</sup>
                  </Label>
                  <FormTextField
                    name="answerThree"
                    id="answerThree"
                    placeholder={`${t("ANSWER")} 03`}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={12} className="d-flex justify-content-end gap-3">
                  <Button onClick={handleCancel} color='secondary w-md'>
                    {t('BACK')}
                  </Button>
                <Button type="submit" color="primary w-md" className="glass-btn">
                { loading ? (
                                      <Spinner size="sm">
                                      Loading...
                                    </Spinner>
                                    ) : "" }
                                  
                                  {' '}{' '}
                  {t("SIGN_UP")}
                </Button>{" "}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      {/* 
      <div className='d-flex justify-content-end w-100 mt-3'>
        <button
          className='btn btn-primary w-lg waves-effect waves-light'
          type='button'
          onClick={modalPopup}
        >
          Modal Button
        </button>
      </div> */}

      {/*Safeguard Modal*/}
      <Modal isOpen={modal} toggle={toggle} centered className="rounded-modal">
        {/*Modal Body*/}
        <ModalBody className="text-center p-0 auth-bg overflow-hidden rounded-5">

          <img className="auth-bg-img" src={loginBg} alt="login-bg"/>

          {/*Modal title*/}
          <div className="d-flex justify-content-center align-items-center gap-1 mb-2 pt-3 px-3 pb-1 position-relative z-3">
            <p className="font-size-14 m-0 text-white-70">Dear</p>
            <h5 className="text-white font-size-16 fw-normal m-0">
              {`${signUpUser?.firstName} ${signUpUser?.lastName}`}
            </h5>
          </div>

          <div className="w-100 px-3 pb-3 position-relative z-3">
            {/*Modal Text*/}
            <p className="mb-2 font-size-12 text-white">
              {t("CONGRATULATIONS")}
              <br />
              <span className="text-white-70">{t("YOU_HAVE_SUCCESSFULLY_SIGNED_UP_FOR_THE_TPS")}</span>
              <br/>
              <span>{`${t("YOU_WILL_INCUR_A_REGISTRATION_FEE_OF")} $${REGISTRATION_ACCOUNT_FEE} ${t("PER_ACCOUNT_AFTER_THE_REGISTRATION_PROCESS_IS_COMPLETED")}`}</span>
            </p>

            {/*Modal Button*/}
            <div className="d-flex justify-content-center mt-3">
              <button
                className="btn btn-secondary w-md waves-effect waves-light font-size-12"
                type="button"
                onClick={modalClose}
              >
                {t("CLOSE")}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default SecurityQuestion;
