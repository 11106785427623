import { Form, Formik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
    Toast,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import FormDropdown from "../../common/formComponents/FormDropdown";
import FormTextField from "../../common/formComponents/FormTextField";
import * as yup from "yup";
import { getBankCurrency, getCountries } from "../../services/CommonService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
    saveQuote,
    getQuoteByUser
} from "../../services/SourceOfFunds";
import MaterialTableCustom from "../../common/tables/MaterialTableCustom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const FXPurchase = () => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.auth.data?.userDTO);

    const [currencies, setCurrencies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);

    const [columns, setColumns] = useState([
        { title: t("QUOTE_ID"), field: "quoteId", width: 200 },
        { title: t("BUY_CURRENCY"), field: "buyCurrency", width: 200 },
        { title: t("SELL_CURRENCY"), field: "sellCurrency", width: 200 },
        { title: t("BUY_AMOUNT"), field: "buyAmount", width: 200 },
        { title: t("SELL_AMOUNT"), field: "sellAmount", width: 200 },
        { title: t("VALID_FROM"), field: "formattedValidFromAt", width: 200 },
        { title: t("VALID_TO"), field: "formattedValidToAt", width: 200 },
        { title: t("RATE"), field: "rate", width: 200 },
    ]);


    useEffect(() => {
        const fetchCurrencies = async () => {
            setLoading(true)
            try {
                const response = await getBankCurrency();
                setCurrencies(response.result);
            } catch (error) {
                toast.error(t("Failed to load currencies"));
            } finally {
                setLoading(false)
            }
        };

        fetchCurrencies();
        getByCoUser();
    }, []);

    const getByCoUser = async () => {
        try {
            setLoading(true)
            const response = await getQuoteByUser(user.coUserId);

            let data = response.result.map((item) => {
                let formattedValidFromAt = "";
                let formattedValidToAt = "";

                if(item.validToAt) {
                    // Parse the date string and format it
                    const parsedDate = new Date(item.validToAt);

                    // Format the hours and minutes with leading zeros
                    const formattedHours = ("0" + parsedDate.getHours()).slice(-2);
                    const formattedMinutes = ("0" + parsedDate.getMinutes()).slice(-2);

                    formattedValidToAt = `${parsedDate.getFullYear()}-${
                        parsedDate.getMonth() + 1
                    }-${parsedDate.getDate()}, ${formattedHours}:${formattedMinutes}`;
                } else {
                    formattedValidToAt = "";
                }

                if (item.validFromAt) {
                    // Parse the date string and format it
                    const parsedDate = new Date(item.validFromAt);

                    // Format the hours and minutes with leading zeros
                    const formattedHours = ("0" + parsedDate.getHours()).slice(-2);
                    const formattedMinutes = ("0" + parsedDate.getMinutes()).slice(-2);

                    formattedValidFromAt = `${parsedDate.getFullYear()}-${
                        parsedDate.getMonth() + 1
                    }-${parsedDate.getDate()}, ${formattedHours}:${formattedMinutes}`;
                } else {
                    formattedValidFromAt = "";
                }

                return {
                    ...item,
                    formattedValidFromAt,
                    formattedValidToAt
                };
            });

            setTableData(data);
        } catch (error) {
            toast.error(t("Failed to load quotes"));
        } finally {
            setLoading(false)
        }
    };

    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true);
        try {
            const data = {
                coUserId: user.coUserId,
                buyCurrency: values.buyCurrency,
                sellCurrency: values.sellCurrency,
                buyAmount: parseFloat(values.buyAmount).toFixed(2),
                validity: values.validPeriod,
            }
            const response = await saveQuote(data);
            await getByCoUser();

            toast.success(t("Quote created successfully!"));
            resetForm();
        } catch (error) {
            toast.error(t("Failed to create quote"));
        } finally {
            setLoading(false);
        }
    };

    const validationSchema = yup.object().shape({
        buyCurrency: yup.string().required(t("Buy Currency is required")),
        // sellCurrency: yup.string().required(t("Sell Currency is required")),
        sellCurrency: yup
            .string()
            .required(t("Sell Currency is required"))
            .test(
                "different-currencies",
                t("Sell Currency and Buy Currency must be different"),
                function (value) {
                    return value !== this.parent.buyCurrency;
                }
            ),
        buyAmount: yup
            .number()
            .required(t("Buy Amount is required"))
            .max(1000000, t("Buy Amount must not exceed 1,000,000"))
            .positive(t("Amount must be a positive number")),
            // .test(
            //     "is-decimal",
            //     t("Buy Amount must have at most two decimal places"),
            //     (value) => /^\d+(\.\d{1,2})?$/.test(value)
            // ),
        validPeriod: yup.string().required(t("Valid Period is required")),
    });

    const validPeriods = [
        { label: "1 hours", value: "HR_1" },
        { label: "4 hours", value: "HR_4" },
        { label: "8 hours", value: "HR_8" },
        { label: "24 hours", value: "HR_24" },
    ];

    document.title = t("FX_PURCHASE");

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="page-title-box p-0">
                        <Row>
                            <Col md={6}>
                                <Breadcrumbs
                                    title={t("TPS_SOLUTIONS")}
                                    breadcrumbItem={t("FX_PURCHASE")}
                                    mainTitleLink="/"
                                    titleLink="#"
                                />
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4 mb-3">
                                        {t("FX_PURCHASE")}
                                    </CardTitle>

                                    <Formik
                                        initialValues={{
                                            buyCurrency: "",
                                            sellCurrency: "",
                                            buyAmount: "",
                                            validPeriod: "",
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({
                                              values,
                                              errors,
                                              touched,
                                              handleChange,
                                              handleSubmit,
                                              setFieldValue,
                                          }) => (
                                            <form onSubmit={handleSubmit}>
                                                <Row>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="buyCurrency">
                                                                {t("Buy Currency")}
                                                            </Label>
                                                            <FormDropdown
                                                                id="buyCurrency"
                                                                name="buyCurrency"
                                                                optionsValues={currencies.map(
                                                                    (currency) => ({
                                                                        label: currency.bank_currency,
                                                                        value: currency.bank_currency,
                                                                    })
                                                                )}
                                                                value={values.buyCurrency}
                                                                onChange={(val) =>
                                                                    setFieldValue(
                                                                        "buyCurrency",
                                                                        val
                                                                    )
                                                                }
                                                                error={
                                                                    touched.buyCurrency &&
                                                                    errors.buyCurrency
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="sellCurrency">
                                                                {t("Sell Currency")}
                                                            </Label>
                                                            <FormDropdown
                                                                id="sellCurrency"
                                                                name="sellCurrency"
                                                                optionsValues={currencies.map(
                                                                    (currency) => ({
                                                                        label: currency.bank_currency,
                                                                        value: currency.bank_currency,
                                                                    })
                                                                )}
                                                                value={values.sellCurrency}
                                                                onChange={(val) =>
                                                                    setFieldValue(
                                                                        "sellCurrency",
                                                                        val
                                                                    )
                                                                }
                                                                error={
                                                                    touched.sellCurrency &&
                                                                    errors.sellCurrency
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="buyAmount">
                                                                {t("Buy Amount")}
                                                            </Label>
                                                            <FormTextField
                                                                id="buyAmount"
                                                                name="buyAmount"
                                                                type="number"
                                                                max={10000000}
                                                                placeholder={t("Buy Amount")}
                                                                onChange={handleChange}
                                                                value={values.buyAmount}
                                                                error={
                                                                    touched.buyAmount &&
                                                                    errors.buyAmount
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="validPeriod">
                                                                {t("Valid Period")}
                                                            </Label>
                                                            <FormDropdown
                                                                id="validPeriod"
                                                                name="validPeriod"
                                                                optionsValues={validPeriods}
                                                                value={values.validPeriod}
                                                                onChange={(val) =>
                                                                    setFieldValue(
                                                                        "validPeriod",
                                                                        val
                                                                    )
                                                                }
                                                                error={
                                                                    touched.validPeriod &&
                                                                    errors.validPeriod
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md={12}>
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            disabled={loading}
                                                        >
                                                            {loading ? (
                                                                <Spinner size="sm"/>
                                                            ) : (
                                                                t("Create Quote")
                                                            )}
                                                        </Button>
                                                    </Col>
                                                </Row>

                                                <br/>
                                                <Row>
                                                    <MaterialTableCustom
                                                        data={tableData}
                                                        columns={columns}
                                                        loading={loading}
                                                    />
                                                </Row>
                                            </form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default FXPurchase;
