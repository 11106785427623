import {
    Badge,
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Label,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row, Spinner
} from "reactstrap";
import {Form, Formik} from "formik";
import FormTextField from "../../../common/formComponents/FormTextField";
import FormDropdown from "../../../common/formComponents/FormDropdown";
import MaterialTableCustom from "../../../common/tables/MaterialTableCustom";
import PayeeTransactionEditModal from "../../Payee/components/payeeTransactionEditModal";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const ViewInquiryModel = (props) =>{
    const { isOpen, toggleModal, rowData, isNew } = props;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const user = useSelector((state) => state.auth.data.userDTO);
    const isAccountActive = user?.isActive;

    // useEffect(()=>{
    //     console.log('rowData',rowData.status)
    // },[])

    const badgeColor = rowData.status === "PENDING" ? "bg-warning" :
        rowData.status === "COMPLETED" ? "bg-custom-success" :
            rowData.status === "REJECTED" ? "bg-danger" : "bg-success";
    const badgeText = rowData.status;

    return(
        <div>
            <Modal isOpen={isOpen} centered={true} size="lg">
                <Formik
                    enableReinitialize
                    initialValues={{
                        date: rowData.date ? rowData.date : "",
                        name: user.firstName ? user.firstName : '',
                        email: user.businessEmail ? user.businessEmail : '',
                        messageTitle: rowData.messageTitle ? rowData.messageTitle : "",
                        message: rowData.description ? rowData.description : "",
                        inquiryId: rowData.inquiryId ? rowData.inquiryId : "",
                    }}
                  //  validationSchema={validationSchema}
                   // onSubmit={onSubmit}
                >
                    {({ isSubmitting, submitForm }) => (
                        <Form>
                            <ModalHeader style={{backgroundColor: 'rgb(3, 35, 112)', color: 'white' }}>
                                {t("INQUIRY_DETAILS")}
                            </ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="date">{t("DATE")}
                                                <sup className="text-danger"> *</sup>
                                            </Label>
                                            <FormTextField
                                                name="date"
                                                id="date"
                                                placeholder={t("DATE")}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="status">{t("STATUS")}
                                            </Label>
                                            <br/>
                                            <Badge className={`font-size-14 p-2 ${badgeColor}`}>{badgeText}</Badge>
                                        </FormGroup>
                                    </Col>

                                    <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="email">{t("INQUIRY_ID")}
                                                <sup className="text-danger"> *</sup>
                                            </Label>
                                            <FormTextField
                                                name="inquiryId"
                                                id="inquiryId"
                                                placeholder={t("INQUIRY_ID")}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="name">{t("NAME")}
                                                <sup className="text-danger"> *</sup>
                                            </Label>
                                            <FormTextField
                                                name="name"
                                                id="name"
                                                placeholder={t("NAME")}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={12} xl={12}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="messageTitle">{t("MESSAGE_TITLE")}
                                                <sup className="text-danger"> *</sup>
                                            </Label>
                                            <FormTextField
                                                name="messageTitle"
                                                type="messageTitle"
                                                id="messageTitle"
                                                placeholder={t("MESSAGE_TITLE")}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={12} xl={12}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="message">{t("MESSAGE")}
                                                <sup className="text-danger"> *</sup>
                                            </Label>
                                            <FormTextField
                                                style={{ minHeight: '100px' }}
                                                name="message"
                                                type="textarea"
                                                id="message"
                                                placeholder={t("MESSAGE")}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="secondary w-md" onClick={toggleModal}>
                                    {t("CLOSE")}
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    )
}

export default ViewInquiryModel;